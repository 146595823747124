import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import { Headernav } from "../../components/HeaderNav/Headernav";
import { Icon } from "../../components/Icon/Icon";
import { NavLink } from "react-router-dom";
import { RoutesEnum } from "../../constants/routes";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { selectPackages } from "../../redux/selectors/packages.selector";
import { AsyncState } from "../../types/redux.type";
import { packageActions } from "../../redux/actions/package.action";
import { selectCurrentUser } from "../../redux/selectors/auth.selector";

export const PackagesScreen = () => {
	const dispatch = useAppDispatch();
	const packages = useAppSelector(selectPackages);
	const user = useAppSelector(selectCurrentUser);

	useEffect(() => {
		dispatch(packageActions.listPackages.start({}));
	}, [dispatch]);

	return (
		<div className={styles.container}>
			<Headernav />
			<div className={styles.card}>
				<header className={styles.header}>
					<h3>Packages</h3>
					{user.data?.role === "admin" ? (
						<div>
							<NavLink to={`${RoutesEnum.PACKAGES}/add`}>Add</NavLink>
							<Icon name="add_circle" />
						</div>
					) : (
						<></>
					)}
				</header>
				<table className={styles.table}>
					<tr>
						<th>ID</th>
						<th>Details</th>
						<th>Price</th>
					</tr>
					{packages.status === AsyncState.success && packages.data ? (
						packages.data.map((packageData) => (
							<tr>
								<td>{packageData.id}</td>
								<td>{packageData.details}</td>
								<td>&#8377;{packageData.price}</td>
							</tr>
						))
					) : (
						<></>
					)}
				</table>
				{packages.status === AsyncState.inProgress ? (
					<div className={styles.info}>Loading...</div>
				) : packages.status === AsyncState.success && !packages.data?.length ? (
					<div className={styles.info}>Nothing to Show</div>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};
