import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncProperty } from "../../types/redux.type";
import { reduxConstants } from "../../constants/redux.constant";
import { addAsyncCase } from "../../utils/redux.util";
import { associateActions } from "../actions/associates.action";
import { AssociateType } from "../../types/associate.type";

type AssociateSliceType = {
	associates: AsyncProperty<AssociateType[]>;
	createdAssociate: AsyncProperty<AssociateType>;
	currentAssociate: AsyncProperty<AssociateType>;
	changePassword: AsyncProperty<boolean>;
	resetPassword: AsyncProperty<boolean>;
	profile: AsyncProperty<AssociateType>;
	page: number;
	hasMore: boolean;
};

const initialState: AssociateSliceType = {
	associates: reduxConstants.defaultAsyncState,
	createdAssociate: reduxConstants.defaultAsyncState,
	currentAssociate: reduxConstants.defaultAsyncState,
	changePassword: reduxConstants.defaultAsyncState,
	resetPassword: reduxConstants.defaultAsyncState,
	profile: reduxConstants.defaultAsyncState,
	page: 0,
	hasMore: true
};

export const associateLabel = "associate";

export const associateSlice = createSlice({
	name: associateLabel,
	initialState,
	reducers: {
		nextPage(state) {
			state.page += 1;
		},
		resetPage(state) {
			state.page = 0
		},
		setHasMore(state, action: PayloadAction<boolean>) {
			state.hasMore = action.payload
		}
	},
	extraReducers: (builder) => {
		addAsyncCase(builder, associateActions.listAssociates, "associates");
		addAsyncCase(
			builder,
			associateActions.associateDetails,
			"currentAssociate"
		);
		addAsyncCase(builder, associateActions.createAssociate, "createdAssociate");
		addAsyncCase(builder, associateActions.changePassword, "changePassword");
		addAsyncCase(builder, associateActions.resetPassword, "resetPassword");
		addAsyncCase(builder, associateActions.profile, "profile");
	},
});

export const associateSliceActions = associateSlice.actions;
