import React, { forwardRef, useMemo } from "react";
import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom";
import { RoutesEnum } from "../../constants/routes";
import { useAppSelector } from "../../hooks/redux.hook";
import { selectMembers } from "../../redux/selectors/members.selector";
import { selectCurrentUser } from "../../redux/selectors/auth.selector";

type TreeNodeProps = {
	id: string;
};

export const TreeNode = forwardRef<HTMLAnchorElement, TreeNodeProps>(
	(props, ref) => {
		const { id } = props;
		const members = useAppSelector(selectMembers);
		const user = useAppSelector(selectCurrentUser);

		const currentNode = useMemo(
			() => members.data?.find((member) => member.id === id),
			[id, members.data]
		);

		return currentNode ? (
			<div className={styles.container}>
				<NavLink
					to={RoutesEnum.VIEW_TREE + currentNode.id}
					ref={ref}
					className={`${styles.node} ${currentNode.package ? styles.active : ""
						}`}

				>
					<ul className={styles.info}>
						<li>{currentNode.id}</li>
						<li className="strong">
							{currentNode.firstName}
							{currentNode.lastName ? ` ${currentNode.lastName}` : ""}
						</li>
						{user.data?.role === "admin" ?
							<>
								<li>{currentNode.email}</li>
								<li><NavLink to={RoutesEnum.MEMBERS + "/" + currentNode.id}>Details</NavLink></li>
							</>
							: <></>
						}
					</ul>
				</NavLink>
				<div className={styles.children}>
					{currentNode.left ? (
						<TreeNode id={currentNode.left} />
					) : (
						<></>
					)}
					{currentNode.right ? (
						<TreeNode id={currentNode.right} />
					) : (
						<></>
					)}
				</div>
			</div>
		) : (
			<></>
		);
	}
);
