import { createSlice } from "@reduxjs/toolkit";
import { AsyncProperty } from "../../types/redux.type";
import { reduxConstants } from "../../constants/redux.constant";
import { addAsyncCase } from "../../utils/redux.util";
import { PackageType } from "../../types/package.type";
import { packageActions } from "../actions/package.action";

type PackageSliceType = {
	packages: AsyncProperty<PackageType[]>;
	createdPackage: AsyncProperty<PackageType>;
};

const initialState: PackageSliceType = {
	packages: reduxConstants.defaultAsyncState,
	createdPackage: reduxConstants.defaultAsyncState,
};

export const packageLabel = "package";

export const packageSlice = createSlice({
	name: packageLabel,
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		addAsyncCase(builder, packageActions.listPackages, "packages");
		addAsyncCase(builder, packageActions.createPackage, "createdPackage");
	},
});

export const packageSliceActions = packageSlice.actions;
