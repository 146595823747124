import React, { useEffect, useMemo, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import { TreeNode } from "../../components/TreeNode/TreeNode";
import styles from "./styles.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { selectMembers } from "../../redux/selectors/members.selector";
import { memberActions } from "../../redux/actions/members.action";
import { AsyncState } from "../../types/redux.type";
import { RoutesEnum } from "../../constants/routes";
import { Icon } from "../../components/Icon/Icon";
import { Headernav } from "../../components/HeaderNav/Headernav";
import { MEMBERS_URL } from "../../constants/url.constant";
import { selectCurrentUser } from "../../redux/selectors/auth.selector";

type ViewTreeScreenProps = {};

export const ViewTreeScreen = (props: ViewTreeScreenProps) => {
	const { id } = useParams();
	const dispatch = useAppDispatch();
	const members = useAppSelector(selectMembers);
	const auth = useAppSelector(selectCurrentUser);
	const rootRef = useRef<HTMLAnchorElement>(null);
	const parent = useMemo(
		() =>
			id
				? members.data?.find((node) => node.id === id)
				: members.data?.find((node) => node.level === 0),
		[id, members.data]
	);

	useEffect(() => {
		dispatch(memberActions.listMembers.start({ id }));
	}, [dispatch, id]);

	useEffect(() => {
		if (members.data && rootRef.current) {
			rootRef.current?.scrollIntoView({
				behavior: "smooth",
				block: "center",
				inline: "center",
			});
		}
	}, [members.data]);

	return (
		<section className={styles.container}>
			<Headernav />
			{members.status === AsyncState.inProgress ? (
				"Loading..."
			) : (
				<div className={styles.main}>
					{parent ? (
						<TreeNode id={parent?.id} ref={rootRef} />
					) : (
						"Nothing to Show"
					)}
				</div>
			)}
			<div className={styles.download}>
				<div onClick={downloadMembers}>
					<Icon name="download" fontSize="2rem" />
				</div>
			</div>
			<div className={styles.addMember}>
				<NavLink to={RoutesEnum.MEMBERS + "/add"}>
					<Icon name="add" fontSize="2rem" />
				</NavLink>
			</div>
		</section>
	);

	function downloadMembers() {
		fetch(MEMBERS_URL + "/export", { method: "POST", headers: { "Content-Type": "application/xlsx", "Authorization": "Bearer " + auth.data?.token } }).then(res => res.blob()).then(blob => {
			const url = window.URL.createObjectURL(new Blob([blob]))
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "members.xlsx");
			link.click();
			link.remove()
		})
	}
};
