import React, { PropsWithChildren, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { memberActions } from "../../redux/actions/members.action";
import { selectCurrentMember } from "../../redux/selectors/members.selector";
import styles from "./styles.module.scss";
import { Headernav } from "../../components/HeaderNav/Headernav";
import { Dropdown } from "../../components/Form/Dropdown/Dropdown";
import { selectPackages } from "../../redux/selectors/packages.selector";
import { Button } from "../../components/Button/Button";
import { packageActions } from "../../redux/actions/package.action";
import { paymentServices } from "../../services/payments.service";
import { RoutesEnum } from "../../constants/routes";
import { selectCurrentUser } from "../../redux/selectors/auth.selector";
import { selectResetPassword } from "../../redux/selectors/members.selector";
import { Fieldset } from "../../components/Form/Fieldset/Fieldset";
import { FormGroup } from "../../components/Form/FormGroup/FormGroup";
import { InputField } from "../../components/Form/InputField/InputField";
import { AsyncState } from "../../types/redux.type";

type MemberDetailsScreenProps = {};

type RowProps = PropsWithChildren & {
	title: string;
};

const Row = (props: RowProps) => {
	const { title, children } = props;
	return (
		<li className={styles.row}>
			<div className={styles.title}>{title}:</div>
			<div>{children}</div>
		</li>
	);
};

export const MemberDetailsScreen = (props: MemberDetailsScreenProps) => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const member = useAppSelector(selectCurrentMember);
	const authUser = useAppSelector(selectCurrentUser);
	const packages = useAppSelector(selectPackages);
	const [error, setError] = useState("");
	const [formShown, setFormShown] = useState(false);
	const resetPassword = useAppSelector(selectResetPassword);

	useEffect(() => {
		if (id) dispatch(memberActions.memberDetails.start({ id }));
	}, [dispatch, id]);

	useEffect(() => {
		if (!member.data?.package) {
			dispatch(packageActions.listPackages.start({}));
		}
	}, [dispatch, member.data]);

	useEffect(() => {
		if (resetPassword.status === AsyncState.success) {
			setFormShown(false);
			dispatch(memberActions.resetPassword.reset());
		}
	}, [dispatch, resetPassword]);

	return (
		<div className={styles.container}>
			<Headernav />
			<div className={styles.card}>
				{member.data ? (
					<>
						<div className={styles.package}>
							{member.data.package ? (
								<span>{member.data.package}</span>
							) : (
								<form onSubmit={activateMember}>
									<input
										type="hidden"
										name="id"
										value={member.data.id}
									/>
									<Dropdown
										name="package"
										entries={(packages.data || []).map((plan) => ({
											value: plan.id,
										}))}
									/>
									<Button type="submit">Activate</Button>
								</form>
							)}
						</div>
						<ul>
							<Row title="ID">{member.data.id}</Row>
							<Row title="Name">
								{member.data.firstName +
									(member.data.lastName ? " " + member.data.lastName : "")}
							</Row>
							{member.data.fatherName ? (
								<Row title="Father's Name">{member.data.fatherName}</Row>
							) : (
								<></>
							)}
							{member.data.sponserId ? (
								<Row title="Sponser">{member.data.sponserId}</Row>
							) : (
								<></>
							)}
							<Row title="Email">{member.data.email}</Row>
							{formShown ? (
								<form onSubmit={onSubmit}>
									<Fieldset title="Password">
										<FormGroup>
											<InputField
												type="password"
												required={true}
												placeholder="New Password"
												autoComplete="new-password"
												name="password"
												disabled={resetPassword.status === AsyncState.inProgress}
											/>
										</FormGroup>
										<FormGroup>
											<InputField
												type="password"
												required={true}
												placeholder="Re-enter Password"
												autoComplete="new-password"
												name="rePassword"
												disabled={resetPassword.status === AsyncState.inProgress}
											/>
										</FormGroup>
										{error || resetPassword.error ? (
											<div className={styles.error}>
												{error || (resetPassword.error as string)}
											</div>
										) : (
											<></>
										)}
										<div className="flex-end">
											<Button
												disabled={resetPassword.status === AsyncState.inProgress}
												onClick={() => setFormShown(false)}
												type="reset"
											>
												Cancel
											</Button>
											<Button
												type="submit"
												disabled={resetPassword.status === AsyncState.inProgress}
											>
												Reset Password
											</Button>
										</div>
									</Fieldset>
								</form>
							) : (
								<Row title="Password">
									******** (
									<NavLink to="#" onClick={() => setFormShown(true)}>
										Change
									</NavLink>
									)
								</Row>
							)}
							<Row title="Phone">{member.data.phone}</Row>
							<Row title="Sponser">{member.data.sponserId || "None"}</Row>
							<Row title="Address">
								<>
									<div>{member.data.line1}</div>
									{member.data.line2 ? <div>{member.data.line2}</div> : <></>}
									{member.data.line3 ? <div>{member.data.line3}</div> : <></>}
									<div>
										{member.data.city}, {member.data.state} -{" "}
										{member.data.postalCode}
									</div>
									<div>{member.data.country}</div>
								</>
							</Row>
							<Row title="Total Revenue">{member.data.revenue}</Row>
							<Row title="Children">
								{member.data.leftCount} - {member.data.rightCount}
							</Row>
							<Row title="Total Completed Pairs">
								{member.data.ratiosCompleted}
							</Row>
						</ul>
					</>
				) : (
					"Member Not Found"
				)}
			</div>
		</div>
	);

	async function activateMember(e: React.FormEvent) {
		e.preventDefault();

		//@ts-ignore
		const id = e.target?.["id"]?.value;
		//@ts-ignore
		const packageId = e.target?.["package"]?.value;

		const activatedMember = await paymentServices.initiate(
			authUser.data?.token || "",
			id,
			packageId
		);

		navigate(RoutesEnum.VIEW_TREE + activatedMember.data.id, {
			replace: true,
		});
	}

	function onSubmit(e: React.FormEvent) {
		e.preventDefault();
		setError("");

		//@ts-ignore
		const password = e.target?.["password"]?.value;
		//@ts-ignore
		const rePassword = e.target?.["rePassword"]?.value;

		if (password !== rePassword) {
			setError("Passwords do not match");
			return;
		}

		if (member.data?.id) {
			dispatch(
				memberActions.resetPassword.start({
					id: member.data.id,
					password,
				})
			);
		}
	}
};
