import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

const _selectPage = (state: RootState) => state.member.page;
const _selectHasMore = (state: RootState) => state.member.hasMore;
const _selectMembers = (state: RootState) => state.member.members;
const _selectRecruits = (state: RootState) => state.member.recruits;
const _selectCurrentMember = (state: RootState) => state.member.currentMember;
const _selectCreatedMember = (state: RootState) => state.member.createdMember;
const _selectResetPassword = (state: RootState) =>
	state.member.resetPassword;

export const selectMembersPage = createDraftSafeSelector(
	_selectPage,
	(state) => state
);

export const selectHasMoreRecruits = createDraftSafeSelector(
	_selectHasMore,
	(state) => state
);

export const selectMembers = createDraftSafeSelector(
	_selectMembers,
	(state) => state
);

export const selectRecruits = createDraftSafeSelector(
	_selectRecruits,
	(state) => state
);

export const selectCurrentMember = createDraftSafeSelector(
	_selectCurrentMember,
	(state) => state
);

export const selectResetPassword = createDraftSafeSelector(
	_selectResetPassword,
	(state) => state
);

export const selectCreatedMember = createDraftSafeSelector(
	_selectCreatedMember,
	(state) => state
);
