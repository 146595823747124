import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

const _selectPage = (state: RootState) => state.product.page;
const _selectHasMore = (state: RootState) => state.product.hasMore;
const _selectProducts = (state: RootState) => state.product.products;
const _selectCurrentProduct = (state: RootState) =>
	state.product.currentProduct;
const _selectCreatedProduct = (state: RootState) =>
	state.product.createdProduct;

export const selectProductPage = createDraftSafeSelector(
	_selectPage,
	(state) => state
);

export const selectHasMoreProducts = createDraftSafeSelector(
	_selectHasMore,
	(state) => state
);

export const selectCurrentProduct = createDraftSafeSelector(
	_selectCurrentProduct,
	(state) => state
);

export const selectProducts = createDraftSafeSelector(
	_selectProducts,
	(state) => state
);

export const selectCreatedProduct = createDraftSafeSelector(
	_selectCreatedProduct,
	(state) => state
);
