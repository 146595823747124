import axios from "axios";
import { PACKAGES_URL } from "../constants/url.constant";
import { PackageType } from "../types/package.type";

const createPackage = async (
	token: string,
	packageData: PackageType
): Promise<{ data: PackageType }> => {
	const { data } = await axios.post(PACKAGES_URL, packageData, {
		headers: {
			Authorization: "Bearer " + token,
		},
	});

	return data;
};

const listPackages = async (
	token: string
): Promise<{ data: PackageType[] }> => {
	const { data } = await axios.get(PACKAGES_URL, {
		headers: {
			Authorization: "Bearer " + token,
		},
	});

	return data;
};

export const packageServices = {
	listPackages,
	createPackage,
};
