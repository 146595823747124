import React, { PropsWithChildren, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { NavLink, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import {
	selectCurrentAssociate,
	selectResetPassword,
} from "../../redux/selectors/associates.selector";
import { associateActions } from "../../redux/actions/associates.action";
import { Headernav } from "../../components/HeaderNav/Headernav";
import { RoutesEnum } from "../../constants/routes";
import { Fieldset } from "../../components/Form/Fieldset/Fieldset";
import { FormGroup } from "../../components/Form/FormGroup/FormGroup";
import { InputField } from "../../components/Form/InputField/InputField";
import { AsyncState } from "../../types/redux.type";
import { Button } from "../../components/Button/Button";

type AssociateDetailsScreenProps = {};

type RowProps = PropsWithChildren & {
	title: string;
};

const Row = (props: RowProps) => {
	const { title, children } = props;
	return (
		<li className={styles.row}>
			<div className={styles.title}>{title}:</div>
			<div>{children}</div>
		</li>
	);
};

export const AssociateDetailsScreen = (props: AssociateDetailsScreenProps) => {
	const { id } = useParams();
	const dispatch = useAppDispatch();
	const associate = useAppSelector(selectCurrentAssociate);
	const [formShown, setFormShown] = useState(false);
	const [error, setError] = useState("");
	const resetPassword = useAppSelector(selectResetPassword);

	useEffect(() => {
		if (id) dispatch(associateActions.associateDetails.start({ id }));
	}, [dispatch, id]);

	useEffect(() => {
		if (resetPassword.status === AsyncState.success) {
			setFormShown(false);
			dispatch(associateActions.resetPassword.reset());
		}
	}, [dispatch, resetPassword]);

	return (
		<div className={styles.container}>
			<Headernav />
			<div className={styles.card}>
				{associate.data ? (
					<ul>
						<Row title="Username">{associate.data.id}</Row>
						<Row title="Name">{associate.data.name}</Row>
						<Row title="Email">{associate.data.email}</Row>
						{formShown ? (
							<form onSubmit={onSubmit}>
								<Fieldset title="Password">
									<FormGroup>
										<InputField
											type="password"
											required={true}
											autoFocus={true}
											placeholder="New Password"
											autoComplete="new-password"
											name="password"
											disabled={resetPassword.status === AsyncState.inProgress}
										/>
									</FormGroup>
									<FormGroup>
										<InputField
											type="password"
											required={true}
											placeholder="Re-enter Password"
											autoComplete="new-password"
											name="rePassword"
											disabled={resetPassword.status === AsyncState.inProgress}
										/>
									</FormGroup>
									{error || resetPassword.error ? (
										<div className={styles.error}>
											{error || (resetPassword.error as string)}
										</div>
									) : (
										<></>
									)}
									<div className="flex-end">
										<Button
											disabled={resetPassword.status === AsyncState.inProgress}
											onClick={() => setFormShown(false)}
											type="reset"
										>
											Cancel
										</Button>
										<Button
											type="submit"
											disabled={resetPassword.status === AsyncState.inProgress}
										>
											Reset Password
										</Button>
									</div>
								</Fieldset>
							</form>
						) : (
							<Row title="Password">
								******** (
								<NavLink to="#" onClick={() => setFormShown(true)}>
									Change
								</NavLink>
								)
							</Row>
						)}
						{associate.data.parent ? (
							<Row title="Parent">
								<NavLink
									to={RoutesEnum.ASSOCIATES + "/" + associate.data.parent}
								>
									{associate.data.parent}
								</NavLink>
							</Row>
						) : (
							<></>
						)}
						<Row title="Sponsers">
							<NavLink to={RoutesEnum.ASSOCIATES + "/" + id + "/members"}>
								{Number(associate.data.members?.length || 0)}
							</NavLink>
						</Row>
						<Row title="Children">
							{associate.data.children.map((node) => (
								<div>
									<NavLink to={RoutesEnum.ASSOCIATES + "/" + node}>
										{node}
									</NavLink>
								</div>
							))}
						</Row>
					</ul>
				) : (
					"Member Not Found"
				)}
			</div>
		</div>
	);

	function onSubmit(e: React.FormEvent) {
		e.preventDefault();
		setError("");

		//@ts-ignore
		const password = e.target?.["password"]?.value;
		//@ts-ignore
		const rePassword = e.target?.["rePassword"]?.value;

		if (password !== rePassword) {
			setError("Passwords do not match");
			return;
		}

		if (id) {
			dispatch(
				associateActions.resetPassword.start({
					id,
					password,
				})
			);
		}
	}
};
