import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { memberLabel, memberSlice } from "./slices/members.slice";
import { authLabel, authSlice } from "./slices/auth.slice";
import { listenerMiddleware } from "./listeners";
import storage from "redux-persist/lib/storage";
import {
	FLUSH,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
	REHYDRATE,
	persistStore,
	persistReducer,
} from "redux-persist";
import { packageLabel, packageSlice } from "./slices/packages.slice";
import { associateLabel, associateSlice } from "./slices/associates.slice";
import { productLabel, productSlice } from "./slices/products.slice";

const reducer = combineReducers({
	[memberLabel]: memberSlice.reducer,
	[packageLabel]: packageSlice.reducer,
	[productLabel]: productSlice.reducer,
	[associateLabel]: associateSlice.reducer,
	[authLabel]: persistReducer(
		{
			key: authLabel,
			storage,
		},
		authSlice.reducer
	),
});

export const store = configureStore({
	devTools: process.env.REACT_APP_ENV === "DEVELOPMENT",
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(listenerMiddleware.middleware),
	reducer,
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
