import { listenerMiddleware } from "./root.listener";
import { memberActions } from "../actions/members.action";
import { memberServices } from "../../services/members.service";
import { RootState } from "../store";
import { handleErrors } from "../../utils/redux.util";
import { memberSliceActions } from "../slices/members.slice";

listenerMiddleware.startListening({
	actionCreator: memberActions.listMembers.start,
	effect: async (action, listenerAPI) => {
		try {
			listenerAPI.cancelActiveListeners();

			const token =
				(listenerAPI.getState() as RootState).auth.user.data?.token || "";

			const result = await listenerAPI.pause(
				memberServices.listMembers(token, action.payload.id)
			);

			listenerAPI.dispatch(memberActions.listMembers.success(result.data));
		} catch (error: unknown) {
			handleErrors(
				error,
				listenerAPI.dispatch,
				memberActions.listMembers.error
			);
		}
	},
});

listenerMiddleware.startListening({
	actionCreator: memberActions.listRecruits.start,
	effect: async (action, listenerAPI) => {
		try {
			const { id } = action.payload;
			listenerAPI.cancelActiveListeners();

			const token =
				(listenerAPI.getState() as RootState).auth.user.data?.token || "";

			const recruits = (listenerAPI.getState() as RootState).member.recruits;
			const page = (listenerAPI.getState() as RootState).member.page;

			const result = await listenerAPI.pause(
				memberServices.listRecruits(token, page, id)
			);

			if (!result.data.length) {
				listenerAPI.dispatch(memberSliceActions.setHasMore(false))
			} else {
				listenerAPI.dispatch(memberSliceActions.nextPage())
			}

			listenerAPI.dispatch(memberActions.listRecruits.success([...recruits.data || [], ...result.data]));
		} catch (error: unknown) {
			handleErrors(
				error,
				listenerAPI.dispatch,
				memberActions.listRecruits.error
			);
		}
	},
});

listenerMiddleware.startListening({
	actionCreator: memberActions.memberDetails.start,
	effect: async (action, listenerAPI) => {
		try {
			listenerAPI.cancelActiveListeners();

			const token =
				(listenerAPI.getState() as RootState).auth.user.data?.token || "";

			const result = await listenerAPI.pause(
				memberServices.getMemberDetails(token, action.payload.id)
			);

			listenerAPI.dispatch(memberActions.memberDetails.success(result.data));
		} catch (error: unknown) {
			handleErrors(
				error,
				listenerAPI.dispatch,
				memberActions.memberDetails.error
			);
		}
	},
});

listenerMiddleware.startListening({
	actionCreator: memberActions.createMember.start,
	effect: async (action, listenerAPI) => {
		try {
			listenerAPI.cancelActiveListeners();

			const token =
				(listenerAPI.getState() as RootState).auth.user.data?.token || "";

			const result = await listenerAPI.pause(
				memberServices.createMember(token, action.payload.member)
			);

			listenerAPI.dispatch(memberActions.createMember.success(result.data));
		} catch (error: unknown) {
			handleErrors(
				error,
				listenerAPI.dispatch,
				memberActions.createMember.error
			);
		}
	},
});

listenerMiddleware.startListening({
	actionCreator: memberActions.resetPassword.start,
	effect: async (action, listenerAPI) => {
		try {
			listenerAPI.cancelActiveListeners();

			const { id, password } = action.payload;

			const token =
				(listenerAPI.getState() as RootState).auth.user.data?.token || "";

			const result = await listenerAPI.pause(
				memberServices.resetPassword(token, id, password)
			);

			listenerAPI.dispatch(memberActions.resetPassword.success(result.data));
		} catch (error: unknown) {
			handleErrors(
				error,
				listenerAPI.dispatch,
				memberActions.resetPassword.error
			);
		}
	},
});