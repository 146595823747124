import React, { PropsWithChildren, useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import styles from "./styles.module.scss";
import { Headernav } from "../../components/HeaderNav/Headernav";
import { selectCurrentProduct } from "../../redux/selectors/products.selector";
import { productActions } from "../../redux/actions/product.action";
import { Button } from "../../components/Button/Button";
import { IconButton } from "../../components/IconButton/IconButton";
import { Icon } from "../../components/Icon/Icon";
import { RoutesEnum } from "../../constants/routes";

type MemberDetailsScreenProps = {};

type RowProps = PropsWithChildren & {
	title: string;
};

const Row = (props: RowProps) => {
	const { title, children } = props;
	return (
		<li className={styles.row}>
			<div className={styles.title}>{title}:</div>
			<div>{children}</div>
		</li>
	);
};

export const ProductDetailsScreen = (props: MemberDetailsScreenProps) => {
	const { id } = useParams();
	const dispatch = useAppDispatch();
	const product = useAppSelector(selectCurrentProduct);
	const [currentIndex, setIndex] = useState(0);
	const [imageIndex, setImageIndex] = useState(0);

	useEffect(() => {
		if (id) {
			dispatch(productActions.getProductDetails.start({ id }));
			setIndex(0);
		}
	}, [dispatch, id]);

	useEffect(() => {
		setImageIndex(0);
	}, [currentIndex]);

	return (
		<div className={styles.container}>
			<Headernav />
			<div className={styles.card}>
				{product.data?.variants[currentIndex] ? (
					<>
						<h3 className="d-flex">
							{product.data.name}
							<NavLink to={`${RoutesEnum.PRODUCTS}/add/${product.data.id}`}>
								<Icon name="add_circle" iconClassName="pointer" />
							</NavLink>
						</h3>
						<div className={styles.imageContainer}>
							<IconButton
								disabled={imageIndex <= 0}
								onClick={() => setImageIndex((prev) => prev - 1)}
								name="chevron_left"
							/>
							<img
								className={styles.image}
								src={product.data.variants[currentIndex].images[imageIndex]}
								alt={product.data.name}
							/>
							<IconButton
								disabled={
									imageIndex >=
									product.data.variants[currentIndex].images.length - 1
								}
								onClick={() => setImageIndex((prev) => prev + 1)}
								name="chevron_right"
							/>
						</div>
						<div>{product.data.description}</div>
						<ul>
							<Row title="Retail Price">
								{product.data.variants[currentIndex].mrp}
							</Row>
							<Row title="Cost Price">
								{product.data.variants[currentIndex].cost}
							</Row>
							<Row title="Selling Price">
								{product.data.variants[currentIndex].price}
							</Row>
							<div className="d-flex">
								<Button
									disabled={currentIndex <= 0}
									onClick={() => setIndex((prev) => prev - 1)}
								>
									Previous
								</Button>
								<Button
									disabled={currentIndex >= product.data.variants.length - 1}
									onClick={() => setIndex((prev) => prev + 1)}
								>
									Next
								</Button>
							</div>
						</ul>
					</>
				) : (
					"Product Not Found"
				)}
			</div>
		</div>
	);
};
