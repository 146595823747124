import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { jwtDecode } from "jwt-decode";

const _selectCurrentUser = (state: RootState) => state.auth.user;

export const selectCurrentUser = createDraftSafeSelector(
	_selectCurrentUser,
	(state) => {
		const tokenData = state.data
			? (jwtDecode(state.data?.token) as Record<string, string>)
			: undefined;
		return {
			...state,
			data: state.data
				? {
						...state.data,
						id: tokenData?.id,
						role: tokenData?.role,
				  }
				: undefined,
		};
	}
);
