import axios from "axios";
import { ASSOCIATE_AUTH_URL, AUTH_URL } from "../constants/url.constant";
import { AuthType } from "../types/admin.type";

const login = async (
	email: string,
	password: string,
	role: string
): Promise<{ data: AuthType }> => {
	if (role === "admin") {
		const { data } = await axios.post(AUTH_URL + "/login", {
			email,
			password,
		});
		return data;
	} else {
		const { data } = await axios.post(ASSOCIATE_AUTH_URL + "/login", {
			email,
			password,
		});
		return data;
	}
};

export const authServices = {
	login,
};
