import { listenerMiddleware } from "./root.listener";
import { authActions } from "../actions/auth.action";
import { authServices } from "../../services/auth.service";
import { handleErrors } from "../../utils/redux.util";

listenerMiddleware.startListening({
	actionCreator: authActions.login.start,
	effect: async (action, listenerAPI) => {
		try {
			listenerAPI.cancelActiveListeners();

			const result = await listenerAPI.pause(
				authServices.login(
					action.payload.email,
					action.payload.password,
					action.payload.role
				)
			);

			listenerAPI.dispatch(authActions.login.success(result.data));
		} catch (error: unknown) {
			handleErrors(error, listenerAPI.dispatch, authActions.login.error);
		}
	},
});
