import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

const _selectPackages = (state: RootState) => state.package.packages;
const _selectCreatedPackage = (state: RootState) =>
	state.package.createdPackage;

export const selectPackages = createDraftSafeSelector(
	_selectPackages,
	(state) => state
);

export const selectCreatedPackage = createDraftSafeSelector(
	_selectCreatedPackage,
	(state) => state
);
