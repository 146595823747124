import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncProperty } from "../../types/redux.type";
import { reduxConstants } from "../../constants/redux.constant";
import { addAsyncCase } from "../../utils/redux.util";
import { memberActions } from "../actions/members.action";
import { MemberType } from "../../types/member.type";

type MemberSliceType = {
	members: AsyncProperty<MemberType[]>;
	recruits: AsyncProperty<MemberType[]>;
	currentMember: AsyncProperty<MemberType>;
	createdMember: AsyncProperty<MemberType>;
	resetPassword: AsyncProperty<boolean>;
	page: number;
	hasMore: boolean;
};

const initialState: MemberSliceType = {
	members: reduxConstants.defaultAsyncState,
	recruits: reduxConstants.defaultAsyncState,
	currentMember: reduxConstants.defaultAsyncState,
	createdMember: reduxConstants.defaultAsyncState,
	resetPassword: reduxConstants.defaultAsyncState,
	page: 0,
	hasMore: true
};

export const memberLabel = "member";

export const memberSlice = createSlice({
	name: memberLabel,
	initialState,
	reducers: {
		nextPage(state) {
			state.page += 1;
		},
		resetPage(state) {
			state.page = 0
		},
		setHasMore(state, action: PayloadAction<boolean>) {
			state.hasMore = action.payload
		}
	},
	extraReducers: (builder) => {
		addAsyncCase(builder, memberActions.listMembers, "members");
		addAsyncCase(builder, memberActions.listRecruits, "recruits");
		addAsyncCase(builder, memberActions.memberDetails, "currentMember");
		addAsyncCase(builder, memberActions.createMember, "createdMember");
		addAsyncCase(builder, memberActions.resetPassword, "resetPassword");
	},
});

export const memberSliceActions = memberSlice.actions;
