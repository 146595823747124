import React, { useState } from "react";
import { FormGroup } from "../../components/Form/FormGroup/FormGroup";
import { InputField } from "../../components/Form/InputField/InputField";
import { Button } from "../../components/Button/Button";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { authActions } from "../../redux/actions/auth.action";
import styles from "./styles.module.scss";
import { selectCurrentUser } from "../../redux/selectors/auth.selector";
import { AsyncState } from "../../types/redux.type";

export const LoginScreen = () => {
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectCurrentUser);
	const [activeTab, setActiveTab] = useState("associate");

	return (
		<main className={styles.container}>
			<div className={styles.card}>
				<header className={styles.tabs}>
					<div
						className={activeTab === "associate" ? styles.active : ""}
						onClick={() => setActiveTab("associate")}
					>
						Associate
					</div>
					<div
						className={activeTab === "admin" ? styles.active : ""}
						onClick={() => setActiveTab("admin")}
					>
						Admin
					</div>
				</header>
				<h3>Login</h3>

				<form onSubmit={onSubmit}>
					<FormGroup>
						<InputField
							type="email"
							placeholder="Enter Email"
							name="email"
							required={true}
							autoFocus={true}
							autoComplete="email"
						/>
					</FormGroup>
					<FormGroup>
						<InputField
							type="password"
							placeholder="Password"
							name="password"
							autoComplete="current-password"
							required={true}
						/>
					</FormGroup>

					{user.error ? (
						<div className={styles.error}>{user.error as string}</div>
					) : (
						<></>
					)}

					<div className="flex-end">
						<Button type="submit" disabled={user.status === AsyncState.inProgress}>Login</Button>
					</div>
				</form>
			</div>
		</main>
	);

	function onSubmit(e: React.FormEvent) {
		e.preventDefault();

		//@ts-ignore
		const email = e.target?.["email"]?.value;
		//@ts-ignore
		const password = e.target?.["password"]?.value;

		dispatch(authActions.login.start({ email, password, role: activeTab }));
	}
};
