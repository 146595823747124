import React from "react";
import styles from "./styles.module.scss";

export type InputFieldProps = {
	label?: string;
	value?: string;
	type: string;
	name?: string;
	onChange?: React.ChangeEventHandler;
	placeholder?: string;
	required?: boolean;
	disabled?: boolean;
	autoFocus?: boolean;
	autoComplete?: string;
	className?: string;
	defaultValue?: string;
	inputRef?: React.RefObject<HTMLInputElement>;
};

export const InputField = (props: InputFieldProps) => {
	const {
		label,
		type,
		name,
		placeholder,
		value,
		onChange,
		required,
		disabled,
		autoFocus,
		autoComplete,
		className,
		defaultValue,
		inputRef,
	} = props;
	return (
		<label className={styles.label}>
			{label ? <span>{label}</span> : <></>}
			<input
				ref={inputRef}
				type={type}
				name={name}
				value={value}
				onChange={onChange}
				required={required}
				disabled={disabled}
				autoFocus={autoFocus}
				autoComplete={autoComplete}
				placeholder={placeholder}
				className={`${styles.input} ${className || ""}`}
				defaultValue={defaultValue}
			/>
		</label>
	);
};
