import { listenerMiddleware } from "./root.listener";
import { associateActions } from "../actions/associates.action";
import { associateServices } from "../../services/associates.service";
import { RootState } from "../store";
import { handleErrors } from "../../utils/redux.util";
import { associateSliceActions } from "../slices/associates.slice";

listenerMiddleware.startListening({
	actionCreator: associateActions.listAssociates.start,
	effect: async (action, listenerAPI) => {
		try {
			listenerAPI.cancelActiveListeners();

			const token =
				(listenerAPI.getState() as RootState).auth.user.data?.token || "";

			const associates = (listenerAPI.getState() as RootState).associate.associates;
			const page = (listenerAPI.getState() as RootState).associate.page;

			const result = await listenerAPI.pause(
				associateServices.listAssociates(token, page)
			);
			if (!result.data.length) {
				listenerAPI.dispatch(associateSliceActions.setHasMore(false))
			} else {
				listenerAPI.dispatch(associateSliceActions.nextPage())
			}

			listenerAPI.dispatch(
				associateActions.listAssociates.success([...associates.data || [], ...result.data])
			);
		} catch (error: unknown) {
			handleErrors(
				error,
				listenerAPI.dispatch,
				associateActions.listAssociates.error
			);
		}
	},
});

listenerMiddleware.startListening({
	actionCreator: associateActions.associateDetails.start,
	effect: async (action, listenerAPI) => {
		try {
			listenerAPI.cancelActiveListeners();

			const token =
				(listenerAPI.getState() as RootState).auth.user.data?.token || "";

			const result = await listenerAPI.pause(
				associateServices.getAssociateDetails(token, action.payload.id)
			);

			listenerAPI.dispatch(
				associateActions.associateDetails.success(result.data)
			);
		} catch (error: unknown) {
			handleErrors(
				error,
				listenerAPI.dispatch,
				associateActions.associateDetails.error
			);
		}
	},
});

listenerMiddleware.startListening({
	actionCreator: associateActions.createAssociate.start,
	effect: async (action, listenerAPI) => {
		try {
			listenerAPI.cancelActiveListeners();

			const token =
				(listenerAPI.getState() as RootState).auth.user.data?.token || "";

			const result = await listenerAPI.pause(
				associateServices.createAssociate(token, action.payload.associate)
			);

			listenerAPI.dispatch(
				associateActions.createAssociate.success(result.data)
			);
		} catch (error: unknown) {
			handleErrors(
				error,
				listenerAPI.dispatch,
				associateActions.createAssociate.error
			);
		}
	},
});

listenerMiddleware.startListening({
	actionCreator: associateActions.changePassword.start,
	effect: async (action, listenerAPI) => {
		try {
			listenerAPI.cancelActiveListeners();

			const { oldPassword, password } = action.payload;

			const token =
				(listenerAPI.getState() as RootState).auth.user.data?.token || "";

			const result = await listenerAPI.pause(
				associateServices.changePassword(token, oldPassword, password)
			);

			listenerAPI.dispatch(
				associateActions.changePassword.success(result.data)
			);
		} catch (error: unknown) {
			handleErrors(
				error,
				listenerAPI.dispatch,
				associateActions.changePassword.error
			);
		}
	},
});

listenerMiddleware.startListening({
	actionCreator: associateActions.resetPassword.start,
	effect: async (action, listenerAPI) => {
		try {
			listenerAPI.cancelActiveListeners();

			const { id, password } = action.payload;

			const token =
				(listenerAPI.getState() as RootState).auth.user.data?.token || "";

			const result = await listenerAPI.pause(
				associateServices.resetPassword(token, id, password)
			);

			listenerAPI.dispatch(associateActions.resetPassword.success(result.data));
		} catch (error: unknown) {
			handleErrors(
				error,
				listenerAPI.dispatch,
				associateActions.resetPassword.error
			);
		}
	},
});

listenerMiddleware.startListening({
	actionCreator: associateActions.profile.start,
	effect: async (action, listenerAPI) => {
		try {
			listenerAPI.cancelActiveListeners();

			const token =
				(listenerAPI.getState() as RootState).auth.user.data?.token || "";

			const result = await listenerAPI.pause(
				associateServices.getProfile(token)
			);

			listenerAPI.dispatch(associateActions.profile.success(result.data));
		} catch (error: unknown) {
			handleErrors(error, listenerAPI.dispatch, associateActions.profile.error);
		}
	},
});
