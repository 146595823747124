import React from "react";
import styles from "./styles.module.scss";

type IconProps = {
	name: string;
	filled?: boolean;
	color?: string;
	fontSize?: string;
	iconClassName?: string;
};

export const Icon = (props: IconProps) => {
	const { name, filled, color, fontSize, iconClassName } = props;
	return (
		<div className={styles.container}>
			<span
				className={`material-symbols-${filled ? "rounded" : "outlined"} ${
					iconClassName ? iconClassName : ""
				}`}
				style={{ color, fontSize }}
			>
				{name}
			</span>
		</div>
	);
};
