import React, { useEffect } from "react";
import { InputField } from "../../components/Form/InputField/InputField";
import { FormGroup } from "../../components/Form/FormGroup/FormGroup";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "../../components/Button/Button";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { selectCreatedMember } from "../../redux/selectors/members.selector";
import { RoutesEnum } from "../../constants/routes";
import { memberActions } from "../../redux/actions/members.action";
import styles from "./styles.module.scss";
import { Fieldset } from "../../components/Form/Fieldset/Fieldset";
import { Headernav } from "../../components/HeaderNav/Headernav";
import { selectCurrentUser } from "../../redux/selectors/auth.selector";
import { AsyncState } from "../../types/redux.type";

export const AddMemberScreen = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectCurrentUser);
	const createdMember = useAppSelector(selectCreatedMember);

	useEffect(() => {
		if (createdMember.data) {
			dispatch(memberActions.createMember.reset());
			navigate(RoutesEnum.VIEW_TREE, { replace: true });
		}
	}, [dispatch, navigate, createdMember.data]);

	return (
		<section className={styles.container}>
			<Headernav />
			<div className={styles.card}>
				<h3>Add Member</h3>
				<form onSubmit={onSubmit}>
					<FormGroup>
						<InputField
							type="text"
							name="fname"
							label="First Name*"
							placeholder="Ex: John"
							required={true}
							autoComplete="give-name"
						/>
					</FormGroup>
					<FormGroup>
						<InputField
							type="text"
							name="lname"
							label="Last Name"
							placeholder="Ex: Doe"
							autoComplete="family-name"
						/>
					</FormGroup>
					<FormGroup>
						<InputField
							type="text"
							name="fatherName"
							label="Father's Name"
							placeholder="Ex: Jason Doe"
							autoComplete="name"
						/>
					</FormGroup>
					<FormGroup>
						<InputField
							type="email"
							name="email"
							label="Email*"
							placeholder="Ex: johndoe@email.com"
							required={true}
							autoComplete="email"
						/>
					</FormGroup>
					<FormGroup>
						<InputField
							type="tel"
							name="phone"
							label="Phone*"
							placeholder="Ex: XXXX-XXX-XXX"
							required={true}
							autoComplete="tel-national"
						/>
					</FormGroup>
					<FormGroup>
						<InputField
							type="date"
							required={true}
							name="dob"
							label="Date of Birth*"
						/>
					</FormGroup>
					<Fieldset title="Address">
						<FormGroup>
							<InputField
								type="text"
								name="line1"
								placeholder="House No/ Apartment No*"
								required={true}
								autoComplete="address-line1"
							/>
						</FormGroup>
						<FormGroup>
							<InputField
								type="text"
								name="line2"
								placeholder="Locality/ Street"
								autoComplete="address-line2"
							/>
						</FormGroup>
						<FormGroup>
							<InputField
								type="text"
								name="line3"
								placeholder="Landmark"
								autoComplete="address-level3"
							/>
						</FormGroup>
						<FormGroup>
							<InputField
								type="text"
								name="city"
								placeholder="City*"
								required={true}
								autoComplete="address-level2"
							/>
						</FormGroup>
						<FormGroup>
							<InputField
								type="text"
								name="state"
								placeholder="State*"
								required={true}
								autoComplete="address-level4"
							/>
						</FormGroup>
						<FormGroup>
							<InputField
								type="text"
								name="postalCode"
								placeholder="Postal Code*"
								required={true}
								autoComplete="postal-code"
							/>
						</FormGroup>
						<FormGroup>
							<InputField
								type="text"
								name="country"
								placeholder="Country*"
								required={true}
								autoComplete="country-name"
							/>
						</FormGroup>
					</Fieldset>
					{user.data?.role === "admin" ? (
						<FormGroup>
							<InputField
								type="text"
								name="sponserId"
								label="Sponser Id"
								autoComplete="username"
							/>
						</FormGroup>
					) : (
						<></>
					)}

					{createdMember.error ? (
						<div className={styles.error}>{createdMember.error as string}</div>
					) : (
						<></>
					)}

					<div className={"flex-end"}>
						<Button type="submit" disabled={createdMember.status === AsyncState.inProgress}>Create Account</Button>
					</div>
				</form>
			</div>
		</section >
	);

	function onSubmit(e: React.FormEvent) {
		e.preventDefault();

		//@ts-ignore
		const fname = e.target?.["fname"]?.value;
		//@ts-ignore
		const lname = e.target?.["lname"]?.value;
		//@ts-ignore
		const fatherName = e.target?.["fatherName"]?.value;
		//@ts-ignore
		const email = e.target?.["email"]?.value;
		//@ts-ignore
		const phone = e.target?.["phone"]?.value;
		//@ts-ignore
		const dob = e.target?.["dob"]?.value;
		//@ts-ignore
		const line1 = e.target?.["line1"]?.value;
		//@ts-ignore
		const line2 = e.target?.["line2"]?.value;
		//@ts-ignore
		const line3 = e.target?.["line3"]?.value;
		//@ts-ignore
		const city = e.target?.["city"]?.value;
		//@ts-ignore
		const state = e.target?.["state"]?.value;
		//@ts-ignore
		const country = e.target?.["country"]?.value;
		//@ts-ignore
		const postalCode = e.target?.["postalCode"]?.value;
		//@ts-ignore
		const sponserId = e.target?.["sponserId"]?.value;

		dispatch(
			memberActions.createMember.start({
				member: {
					firstName: fname,
					lastName: lname,
					email,
					phone,
					line1,
					line2,
					line3,
					postalCode,
					city,
					state,
					country,
					dob,
					fatherName,
					sponserId,
					id: id ? Number(id) : undefined,
				},
			})
		);
	}
};
