import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoginScreen } from "../LoginScreen/LoginScreen";
import { ViewTreeScreen } from "../ViewTreeScreen/ViewTreeScreen";
import { RoutesEnum } from "../../constants/routes";
import { MemberDetailsScreen } from "../MemberDetailsScreen/MemberDetailsScreen";
import { AddMemberScreen } from "../AddMemberScreen/AddMemberScreen";
import { GuardRoute } from "../GuardRoute/GuardRoute";
import { PackagesScreen } from "../PackagesScreen/PackagesScreen";
import { AddPackageScreen } from "../AddPackageScreen/AddPackageScreen";
import { AssociatesScreen } from "../AssociatesScreen/AssociatesScreen";
import { AddAssociateScreen } from "../AddAssociateScreen/AddAssociateScreen";
import { AssociateDetailsScreen } from "../AssociateDetailsScreen/AssociateDetailsScreen";
import { ProductsScreen } from "../ProductsScreen/ProductsScreen";
import { AddProductScreen } from "../AddProductScreen/AddProductScreen";
import { ProductDetailsScreen } from "../ProductDetailsScreen/ProductDetailsScreen";
import { NotFoundScreen } from "../NotFoundScreen/NotFoundScreen";
import { ProfileScreen } from "../ProfileScreen/ProfileScreen";
import { MembersScreen } from "../MembersScreen/MembersScreen";

export const AppRouter = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route element={<GuardRoute />}>
					<Route path={RoutesEnum.LOGIN} Component={LoginScreen} />
				</Route>
				<Route element={<GuardRoute guarded={true} roles={["admin"]} />}>
					<Route path={RoutesEnum.VIEW_TREE}>
						<Route path={":id"} Component={ViewTreeScreen} />
						<Route index Component={ViewTreeScreen} />
					</Route>
					<Route
						path={RoutesEnum.MEMBERS + "/:id"}
						Component={MemberDetailsScreen}
					/>
					<Route path={RoutesEnum.ASSOCIATES}>
						<Route index Component={AssociatesScreen} />
						<Route path=":id" Component={AssociateDetailsScreen} />
						<Route path=":id/members" Component={MembersScreen} />
						<Route path="add" Component={AddAssociateScreen} />
					</Route>
					<Route path={RoutesEnum.PRODUCTS + "/add"}>
						<Route path=":id" Component={AddProductScreen} />
						<Route index Component={AddProductScreen} />
					</Route>
					<Route
						path={RoutesEnum.PACKAGES + "/add"}
						Component={AddPackageScreen}
					/>
				</Route>
				<Route element={<GuardRoute guarded={true} roles={["associate"]} />}>
					<Route path={RoutesEnum.MEMBERS} Component={MembersScreen} />
					<Route path={RoutesEnum.PROFILE} Component={ProfileScreen} />
				</Route>
				<Route
					element={<GuardRoute guarded={true} roles={["admin", "associate"]} />}
				>
					<Route path={RoutesEnum.PRODUCTS}>
						<Route index Component={ProductsScreen} />
						<Route path=":id" Component={ProductDetailsScreen} />
					</Route>
					<Route path={RoutesEnum.MEMBERS + "/add"}>
						<Route index Component={AddMemberScreen} />
						<Route path=":id" Component={AddMemberScreen} />
					</Route>
					<Route path={RoutesEnum.PACKAGES}>
						<Route index Component={PackagesScreen} />
					</Route>
				</Route>
				<Route path="*" Component={NotFoundScreen} />
			</Routes>
		</BrowserRouter>
	);
};
