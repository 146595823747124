import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import { Headernav } from "../../components/HeaderNav/Headernav";
import { FormGroup } from "../../components/Form/FormGroup/FormGroup";
import { InputField } from "../../components/Form/InputField/InputField";
import { Button } from "../../components/Button/Button";
import { Textarea } from "../../components/Form/Textarea/Textarea";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { productActions } from "../../redux/actions/product.action";
import {
	selectCreatedProduct,
	selectCurrentProduct,
} from "../../redux/selectors/products.selector";
import { useNavigate, useParams } from "react-router-dom";
import { RoutesEnum } from "../../constants/routes";
import { Icon } from "../../components/Icon/Icon";
import { AsyncState } from "../../types/redux.type";

export const AddProductScreen = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const [images, setImages] = useState<string[]>([]);
	const imageURLRef = useRef<HTMLInputElement>(null);

	const createdproduct = useAppSelector(selectCreatedProduct);
	const currentproduct = useAppSelector(selectCurrentProduct);

	useEffect(() => {
		if (id) {
			dispatch(productActions.getProductDetails.start({ id }));
		} else {
			dispatch(productActions.getProductDetails.reset());
		}
	}, [dispatch, id]);

	useEffect(() => {
		if (createdproduct.data) {
			dispatch(productActions.createProduct.reset());
			navigate(RoutesEnum.PRODUCTS, { replace: true });
		}
	}, [dispatch, navigate, createdproduct.data]);

	return (
		<section className={styles.container}>
			<Headernav />
			<div className={styles.card}>
				<h3>Add Product</h3>
				<form onSubmit={onSubmit}>
					<FormGroup>
						<InputField
							type="text"
							name="id"
							label="PRODUCT Id*"
							placeholder="PRO_123"
							required={true}
							autoComplete="off"
							defaultValue={currentproduct.data?.id}
							disabled={Boolean(currentproduct.data)}
						/>
					</FormGroup>
					<FormGroup>
						<InputField
							type="text"
							name="name"
							label="Product Name*"
							placeholder="Ex: Product Name"
							autoComplete="on"
							required={true}
							defaultValue={currentproduct.data?.name}
							disabled={Boolean(currentproduct.data)}
						/>
					</FormGroup>
					<FormGroup>
						<InputField
							type="number"
							name="mrp"
							label="Retail Price*"
							placeholder="Ex: 1299"
							autoComplete="off"
							required={true}
						/>
					</FormGroup>
					<FormGroup>
						<InputField
							type="number"
							name="price"
							label="Selling Price*"
							placeholder="Ex: 1099"
							autoComplete="off"
							required={true}
						/>
					</FormGroup>
					<FormGroup>
						<InputField
							type="number"
							name="cost"
							label="Cost Price*"
							placeholder="Ex: 899"
							autoComplete="off"
							required={true}
						/>
					</FormGroup>
					<header>Images*</header>
					<div className={styles.previewContainer}>
						{images.map((image, i) => (
							<div className={styles.preview} key={image + i}>
								<img src={image} alt={"Preview " + (i + 1)} />
								<div
									className={styles.icon}
									onClick={() =>
										setImages((prev) => [
											...prev.slice(0, i),
											...prev.slice(i + 1),
										])
									}
								>
									<Icon name="close" fontSize="0.75rem" />
								</div>
							</div>
						))}
					</div>
					<FormGroup className="d-flex align-center gap-1">
						<div className="flex-1">
							<InputField
								inputRef={imageURLRef}
								type="text"
								placeholder="https://link-to-image.png"
								required={false}
							/>
						</div>
						<div>
							<Button onClick={addImageURL}>Add</Button>
						</div>
					</FormGroup>
					<FormGroup>
						<Textarea
							name="description"
							label="Description*"
							placeholder="Ex: Information about the product"
							required={true}
							rows={5}
							defaultValue={currentproduct.data?.description}
							disabled={Boolean(currentproduct.data)}
						/>
					</FormGroup>

					{createdproduct.error ? (
						<div className={styles.error}>{createdproduct.error as string}</div>
					) : (
						<></>
					)}

					<div className={"flex-end"}>
						<Button type="submit" disabled={currentproduct.data ? currentproduct.status === AsyncState.inProgress : createdproduct.status === AsyncState.inProgress}>{`Add ${currentproduct.data ? "Variant" : "Product"
							}`}</Button>
					</div>
				</form>
			</div>
		</section>
	);

	function onSubmit(e: React.FormEvent) {
		e.preventDefault();

		//@ts-ignore
		const id = e.target?.["id"]?.value;
		//@ts-ignore
		const name = e.target?.["name"]?.value;
		//@ts-ignore
		const mrp = Number(e.target?.["mrp"]?.value);
		//@ts-ignore
		const price = Number(e.target?.["price"]?.value);
		//@ts-ignore
		const cost = Number(e.target?.["cost"]?.value);
		//@ts-ignore
		const description = e.target?.["description"]?.value;

		dispatch(
			productActions.createProduct.start({
				product: {
					id,
					name,
					description,
					mrp,
					cost,
					price,
					images,
				},
			})
		);
	}

	function addImageURL(e: React.MouseEvent) {
		e.preventDefault();
		setImages((prev) => {
			const val = "" + imageURLRef.current?.value;

			// if (imageURLRef.current?.value) imageURLRef.current.value = "";
			if (val) return [...prev, val];
			return prev;
		});
	}
};
