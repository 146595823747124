import axios from "axios";
import { PRODUCTS_URL } from "../constants/url.constant";
import { ProductType } from "../types/product.type";

const listProducts = async (
	token: string,
	page: number
): Promise<{ data: ProductType[] }> => {
	const { data } = await axios.get(PRODUCTS_URL + `?offset=${page * 10}`, {
		headers: {
			Authorization: "Bearer " + token,
		},
	});

	return data;
};

const createProduct = async (
	token: string,
	productData: any
): Promise<{ data: ProductType }> => {
	const { data } = await axios.post(PRODUCTS_URL, productData, {
		headers: {
			Authorization: "Bearer " + token,
		},
	});

	return data;
};

const getProductDetails = async (
	token: string,
	id: string
): Promise<{ data: ProductType }> => {
	const { data } = await axios.get(PRODUCTS_URL + "/" + id, {
		headers: {
			Authorization: "Bearer " + token,
		},
	});

	return data;
};

export const productServices = {
	createProduct,
	listProducts,
	getProductDetails,
};
