import React, { PropsWithChildren } from "react";
import styles from "./styles.module.scss";

export type ButtonProps = PropsWithChildren & {
	onClick?: React.MouseEventHandler;
	type?: "button" | "submit" | "reset";
	className?: string;
	disabled?: boolean;
};

export const Button = (props: ButtonProps) => {
	const { type, onClick, children, className, disabled } = props;
	return (
		<div className={styles.container}>
			<button
				className={`${styles.button} ${className || ""}`}
				type={type}
				onClick={onClick}
				disabled={disabled}
			>
				{children}
			</button>
		</div>
	);
};
