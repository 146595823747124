import React, { useEffect } from "react";
import { InputField } from "../../components/Form/InputField/InputField";
import { FormGroup } from "../../components/Form/FormGroup/FormGroup";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button/Button";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { selectCreatedPackage } from "../../redux/selectors/packages.selector";
import { RoutesEnum } from "../../constants/routes";
import { packageActions } from "../../redux/actions/package.action";
import { Headernav } from "../../components/HeaderNav/Headernav";
import styles from "./styles.module.scss";
import { Textarea } from "../../components/Form/Textarea/Textarea";
import { AsyncState } from "../../types/redux.type";

export const AddPackageScreen = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const createdPackage = useAppSelector(selectCreatedPackage);

	useEffect(() => {
		if (createdPackage.data) {
			dispatch(packageActions.createPackage.reset());
			navigate(RoutesEnum.PACKAGES, { replace: true });
		}
	}, [dispatch, navigate, createdPackage.data]);

	return (
		<section className={styles.container}>
			<Headernav />
			<div className={styles.card}>
				<h3>Add Package</h3>
				<form onSubmit={onSubmit}>
					<FormGroup>
						<InputField
							type="text"
							name="id"
							label="Package Id*"
							placeholder="PCK_123"
							required={true}
							autoComplete="off"
						/>
					</FormGroup>
					<FormGroup>
						<InputField
							type="number"
							name="price"
							label="Package Price*"
							placeholder="Ex: 1099"
							required={true}
							autoComplete="off"
						/>
					</FormGroup>
					<FormGroup>
						<Textarea
							name="details"
							label="Package Details*"
							placeholder="Ex: Package Plan"
							rows={5}
							required={true}
						/>
					</FormGroup>

					{createdPackage.error ? (
						<div className={styles.error}>{createdPackage.error as string}</div>
					) : (
						<></>
					)}

					<div className={"flex-end"}>
						<Button type="submit" disabled={createdPackage.status === AsyncState.inProgress}>Create Package</Button>
					</div>
				</form>
			</div>
		</section>
	);

	function onSubmit(e: React.FormEvent) {
		e.preventDefault();

		//@ts-ignore
		const id = e.target?.["id"]?.value;
		//@ts-ignore
		const details = e.target?.["details"]?.value;
		//@ts-ignore
		const price = e.target?.["price"]?.value;

		dispatch(
			packageActions.createPackage.start({
				package: {
					id,
					details,
					price: Number(price),
				},
			})
		);
	}
};
