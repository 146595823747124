import {
	ActionCreatorWithNonInferrablePayload,
	ActionReducerMapBuilder,
	TaskAbortError,
	ThunkDispatch,
	UnknownAction,
	createAction,
} from "@reduxjs/toolkit";
import { AsyncAction, AsyncState } from "../types/redux.type";
import { AxiosError } from "axios";
import { authActions } from "../redux/actions/auth.action";

export const asyncActionCreator = <
	InitialPayload,
	SuccessPayload,
	ErrorPayload = unknown
>(
	type: string
) => ({
	start: createAction<InitialPayload>(`${type}/start`),
	success: createAction<SuccessPayload>(`${type}/success`),
	error: createAction<ErrorPayload>(`${type}/error`),
	reset: createAction(`${type}/reset`),
});

export const addAsyncCase = (
	builder: ActionReducerMapBuilder<Record<string, unknown>>,
	action: AsyncAction,
	property: string
) => {
	builder.addCase(action.start, (state: any) => {
		state[property].status = AsyncState.inProgress;
		state[property].error = undefined;
	});
	builder.addCase(action.success, (state: any, _action) => {
		state[property].status = AsyncState.success;
		state[property].error = undefined;
		state[property].data = _action.payload;
	});
	builder.addCase(action.error, (state: any, _action) => {
		state[property].status = AsyncState.failed;
		state[property].error = _action.payload || {
			code: 500,
			message: "Something went wrong",
		};
	});
	builder.addCase(action.reset, (state: any, _action) => {
		state[property].status = AsyncState.idle;
		state[property].error = undefined;
		state[property].data = undefined;
	});
};

export const handleErrors = (
	error: unknown,
	dispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
	action: ActionCreatorWithNonInferrablePayload
) => {
	if (error instanceof TaskAbortError) return;
	if (error instanceof AxiosError) {
		if (error.response?.status === 403 || error.response?.status === 401) {
			dispatch(authActions.logout());
		}
		dispatch(
			action(
				error.response?.data.message ||
					"Request failed with code " + error.code ||
					"Something went wrong"
			)
		);
	}
};
