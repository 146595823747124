import React, { useEffect } from "react";
import { Headernav } from "../../components/HeaderNav/Headernav";
import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom";
import { RoutesEnum } from "../../constants/routes";
import { Icon } from "../../components/Icon/Icon";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { selectHasMoreProducts, selectProducts } from "../../redux/selectors/products.selector";
import { productActions } from "../../redux/actions/product.action";
import InfiniteScroll from "react-infinite-scroller";
import { productSliceActions } from "../../redux/slices/products.slice";

export const ProductsScreen = () => {
	const dispatch = useAppDispatch();
	const products = useAppSelector(selectProducts);
	const hasMore = useAppSelector(selectHasMoreProducts)

	useEffect(() => {
		dispatch(productActions.listProducts.reset());
		dispatch(productSliceActions.resetPage())
		dispatch(productSliceActions.setHasMore(true))
		dispatch(productActions.listProducts.start({}));
	}, [dispatch]);

	const loadMore = () => {
		dispatch(productActions.listProducts.start({}));
	}

	return (
		<section className={styles.container}>
			<Headernav />
			<InfiniteScroll
				className={styles.main}
				pageStart={0}
				loadMore={loadMore}
				hasMore={hasMore}
				loader={<div className="loader" key={0}>Loading ...</div>}
			>
				{products.data?.length
					? products.data.map((product) => (
						<NavLink
							key={product.id}
							to={RoutesEnum.PRODUCTS + "/" + product.id}
							className={styles.row}
						>
							<div>{product.name}</div>
							<div>{product.id}</div>
						</NavLink>
					))
					: "Nothing to Show"}
			</InfiniteScroll>
			<div className={styles.addMember}>
				<NavLink to={RoutesEnum.PRODUCTS + "/add"}>
					<Icon name="add" fontSize="2rem" />
				</NavLink>
			</div>
		</section>
	);
};
