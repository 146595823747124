import React, { PropsWithChildren } from "react";
import styles from "./styles.module.scss";

export type FormGroupProps = PropsWithChildren & {
	className?: string;
};

export const FormGroup = (props: FormGroupProps) => {
	const { children, className } = props;
	return (
		<div className={`${styles.container} ${className ? className : ""}`}>
			{children}
		</div>
	);
};
