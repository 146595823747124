import React from "react";
import { NavLink } from "react-router-dom";
import { RoutesEnum } from "../../constants/routes";
import { Icon } from "../Icon/Icon";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { authSliceActions } from "../../redux/slices/auth.slice";
import styles from "./styles.module.scss";
import { selectCurrentUser } from "../../redux/selectors/auth.selector";

export const Headernav = () => {
	const dispatch = useAppDispatch();
	const user = useAppSelector(selectCurrentUser);

	return (
		<>
			<div className={styles.heading}>
				<div>
					<NavLink to={"/"}>Addira</NavLink>
				</div>
				<nav>
					{user.data?.role === "admin" ? (
						<>
							<NavLink to={RoutesEnum.VIEW_TREE}>Members</NavLink>
							<NavLink to={RoutesEnum.ASSOCIATES}>Associates</NavLink>
							<NavLink to={RoutesEnum.PRODUCTS}>Products</NavLink>
						</>
					) : user.data?.role === "associate" ? (
						<>
							<NavLink to={RoutesEnum.MEMBERS}>Members</NavLink>
							<NavLink to={RoutesEnum.PROFILE}>Profile</NavLink>
						</>
					) : (
						<>
						</>
					)}
					<NavLink to={RoutesEnum.PACKAGES}>Packages</NavLink>
					<div onClick={logout}>
						<Icon name="logout" fontSize="2rem" iconClassName={"pointer"} />
					</div>
				</nav>
			</div>
			<div className={styles.offset}></div>
		</>
	);

	function logout() {
		dispatch(authSliceActions.logout());
	}
};
