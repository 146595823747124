import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AsyncProperty } from "../../types/redux.type";
import { reduxConstants } from "../../constants/redux.constant";
import { addAsyncCase } from "../../utils/redux.util";
import { AuthType } from "../../types/admin.type";
import { authActions } from "../actions/auth.action";

type AuthSliceType = {
	user: AsyncProperty<AuthType>;
};

const initialState: AuthSliceType = {
	user: reduxConstants.defaultAsyncState,
};

export const authLabel = "auth";

export const authSlice = createSlice({
	name: authLabel,
	initialState,
	reducers: {
		refresh: (state, action: PayloadAction<{ token: string }>) => {
			if (state.user.data?.token) state.user.data.token = action.payload.token;
		},
		logout: (state) => {
			return initialState;
		},
	},
	extraReducers: (builder) => {
		addAsyncCase(builder, authActions.login, "user");
	},
});

export const authSliceActions = authSlice.actions;
