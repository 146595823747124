import React, { useEffect, useState } from "react";
import { InputField } from "../../components/Form/InputField/InputField";
import { FormGroup } from "../../components/Form/FormGroup/FormGroup";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button/Button";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { RoutesEnum } from "../../constants/routes";
import { Headernav } from "../../components/HeaderNav/Headernav";
import styles from "./styles.module.scss";
import { associateActions } from "../../redux/actions/associates.action";
import { Dropdown } from "../../components/Form/Dropdown/Dropdown";
import { selectCreatedAssociate } from "../../redux/selectors/associates.selector";
import { AsyncState } from "../../types/redux.type";

export const AddAssociateScreen = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [error, setError] = useState("");
	const createdAssociate = useAppSelector(selectCreatedAssociate);

	useEffect(() => {
		if (createdAssociate.data) {
			dispatch(associateActions.createAssociate.reset());
			navigate(RoutesEnum.ASSOCIATES, { replace: true });
		}
	}, [dispatch, navigate, createdAssociate.data]);

	return (
		<section className={styles.container}>
			<Headernav />
			<div className={styles.card}>
				<h3>Add Associate</h3>
				<form onSubmit={onSubmit}>
					<FormGroup>
						<InputField
							type="text"
							name="id"
							label="Associate ID*"
							placeholder="ASSOC_123"
							required={true}
						/>
					</FormGroup>
					<FormGroup>
						<InputField
							type="text"
							name="name"
							label="Full Name*"
							placeholder="Ex: John Doe"
							required={true}
							autoComplete="username"
						/>
					</FormGroup>
					<FormGroup>
						<InputField
							type="email"
							name="email"
							label="Email Address*"
							placeholder="Ex: johndoe@email.com"
							required={true}
							autoComplete="email"
						/>
					</FormGroup>
					<FormGroup>
						<InputField
							type="password"
							name="password"
							label="Enter Password*"
							required={true}
							autoComplete="new-password"
						/>
					</FormGroup>
					<FormGroup>
						<InputField
							type="password"
							name="repassword"
							label="Re-enter Password*"
							required={true}
							autoComplete="new-password"
						/>
					</FormGroup>
					<FormGroup>
						<Dropdown
							name="level"
							label="Level*"
							entries={[
								{ value: "Associate Partner" },
								{ value: "Sr. Associate Partner" },
								{ value: "Managing Partner" },
								{ value: "Sr. Managing Partner" },
							]}
							defaultValue="Associate Partner"
							required={true}
						/>
					</FormGroup>
					<FormGroup>
						<InputField
							type="text"
							name="parent"
							label="Parent ID"
							placeholder="ASSOC_123"
							autoComplete="off"
						/>
					</FormGroup>

					{error || createdAssociate.error ? (
						<div className={styles.error}>
							{error || (createdAssociate.error as string)}
						</div>
					) : (
						<></>
					)}

					<div className={"flex-end"}>
						<Button type="submit" disabled={createdAssociate.status === AsyncState.inProgress}>Register Associate</Button>
					</div>
				</form>
			</div>
		</section>
	);

	function onSubmit(e: React.FormEvent) {
		e.preventDefault();

		setError("");

		//@ts-ignore
		const id = e.target?.["id"]?.value;
		//@ts-ignore
		const name = e.target?.["name"]?.value;
		//@ts-ignore
		const email = e.target?.["email"]?.value;
		//@ts-ignore
		const password = e.target?.["password"]?.value;
		//@ts-ignore
		const repassword = e.target?.["repassword"]?.value;
		//@ts-ignore
		const level = e.target?.["level"]?.value;
		//@ts-ignore
		const parent = e.target?.["parent"]?.value;

		if (password !== repassword) {
			setError("Passwords don't match");
		}

		dispatch(
			associateActions.createAssociate.start({
				associate: {
					id,
					name,
					email,
					password,
					level,
					parent,
				},
			})
		);
	}
};
