import React from "react";
import styles from "./styles.module.scss";

export type TextareaProps = {
	label?: string;
	value?: string;
	name?: string;
	onChange?: React.ChangeEventHandler;
	placeholder?: string;
	required?: boolean;
	disabled?: boolean;
	autoFocus?: boolean;
	className?: string;
	defaultValue?: string;
	rows?: number;
};

export const Textarea = (props: TextareaProps) => {
	const {
		label,
		name,
		placeholder,
		value,
		onChange,
		required,
		disabled,
		autoFocus,
		className,
		defaultValue,
		rows,
	} = props;
	return (
		<label className={styles.label}>
			{label ? <span>{label}</span> : <></>}
			<textarea
				name={name}
				value={value}
				onChange={onChange}
				required={required}
				disabled={disabled}
				autoFocus={autoFocus}
				placeholder={placeholder}
				className={`${styles.input} ${className || ""}`}
				defaultValue={defaultValue}
				rows={rows || 3}
			></textarea>
		</label>
	);
};
