import { MemberType } from "../../types/member.type";
import { asyncActionCreator } from "../../utils/redux.util";
import { memberSliceActions } from "../slices/members.slice";

export const memberActionTypes = {
	LIST_MEMBERS: "MEMBERS/LIST",
	LIST_RECRUITS: "MEMBERS/LIST_RECRUITS",
	MEMBER_DETAILS: "MEMBERS/DETAILS",
	ADD_MEMBER: "MEMBERS/ADD",
	RESET_PASSWORD: "MEMBERS/RESET_PASSWORD",
};

export const memberActions = {
	...memberSliceActions,
	listMembers: asyncActionCreator<{ id?: string }, MemberType[], unknown>(
		memberActionTypes.LIST_MEMBERS
	),
	listRecruits: asyncActionCreator<{ id?: string }, MemberType[], unknown>(
		memberActionTypes.LIST_RECRUITS
	),
	createMember: asyncActionCreator<{ member: any }, MemberType, unknown>(
		memberActionTypes.ADD_MEMBER
	),
	memberDetails: asyncActionCreator<{ id: string }, MemberType, unknown>(
		memberActionTypes.MEMBER_DETAILS
	),
	resetPassword: asyncActionCreator<
		{ id: string; password: string },
		boolean,
		unknown
	>(memberActionTypes.RESET_PASSWORD),
};
