import { AssociateType } from "../../types/associate.type";
import { asyncActionCreator } from "../../utils/redux.util";
import { associateSliceActions } from "../slices/associates.slice";

export const associateActionTypes = {
	LIST_ASSOCIATES: "ASSOCIATES/LIST",
	ASSOCIATE_DETAILS: "ASSOCIATES/DETAILS",
	ADD_ASSOCIATE: "ASSOCIATES/ADD",
	CHANGE_PASSWORD: "ASSOCIATES/CHANGE_PASSWORD",
	RESET_PASSWORD: "ASSOCIATES/RESET_PASSWORD",
	PROFILE: "ASSOCIATES/PROFILE",
};

export const associateActions = {
	...associateSliceActions,
	listAssociates: asyncActionCreator<{}, AssociateType[], unknown>(
		associateActionTypes.LIST_ASSOCIATES
	),
	createAssociate: asyncActionCreator<
		{ associate: any },
		AssociateType,
		unknown
	>(associateActionTypes.ADD_ASSOCIATE),
	associateDetails: asyncActionCreator<{ id: string }, AssociateType, unknown>(
		associateActionTypes.ASSOCIATE_DETAILS
	),
	changePassword: asyncActionCreator<
		{ oldPassword: string; password: string },
		boolean,
		unknown
	>(associateActionTypes.CHANGE_PASSWORD),
	resetPassword: asyncActionCreator<
		{ id: string; password: string },
		boolean,
		unknown
	>(associateActionTypes.RESET_PASSWORD),
	profile: asyncActionCreator<{}, AssociateType, unknown>(
		associateActionTypes.PROFILE
	),
};
