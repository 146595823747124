import React from "react";
import styles from "./styles.module.scss";

export type DropdownProps = {
	label?: string;
	value?: string;
	name?: string;
	onChange?: React.ChangeEventHandler;
	required?: boolean;
	disabled?: boolean;
	autoFocus?: boolean;
	className?: string;
	entries: { name?: string; value: string }[];
	defaultValue?: string;
};

export const Dropdown = (props: DropdownProps) => {
	const {
		label,
		name,
		value,
		entries,
		onChange,
		required,
		disabled,
		autoFocus,
		className,
		defaultValue,
	} = props;
	return (
		<label className={styles.label}>
			{label ? <span>{label}</span> : <></>}
			<select
				name={name}
				value={value}
				onChange={onChange}
				required={required}
				disabled={disabled}
				autoFocus={autoFocus}
				defaultValue={defaultValue}
				className={`${styles.input} ${className || ""}`}
			>
				{entries.map((entry) => (
					<option value={entry.value}>{entry.name || entry.value}</option>
				))}
			</select>
		</label>
	);
};
