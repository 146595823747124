import React, { PropsWithChildren, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import {
	selectChangedPassword,
	selectProfile,
} from "../../redux/selectors/associates.selector";
import { associateActions } from "../../redux/actions/associates.action";
import { Headernav } from "../../components/HeaderNav/Headernav";
import { RoutesEnum } from "../../constants/routes";
import { Button } from "../../components/Button/Button";
import { FormGroup } from "../../components/Form/FormGroup/FormGroup";
import { InputField } from "../../components/Form/InputField/InputField";
import { Fieldset } from "../../components/Form/Fieldset/Fieldset";
import { AsyncState } from "../../types/redux.type";

type RowProps = PropsWithChildren & {
	title: string;
};

const Row = (props: RowProps) => {
	const { title, children } = props;
	return (
		<li className={styles.row}>
			<div className={styles.title}>{title}:</div>
			<div>{children}</div>
		</li>
	);
};

export const ProfileScreen = () => {
	const dispatch = useAppDispatch();
	const profile = useAppSelector(selectProfile);
	const [formShown, setFormShown] = useState(false);
	const [error, setError] = useState("");
	const changedPassword = useAppSelector(selectChangedPassword);

	useEffect(() => {
		dispatch(associateActions.profile.start({}));
	}, [dispatch]);

	useEffect(() => {
		if (changedPassword.status === AsyncState.success) {
			setFormShown(false);
			dispatch(associateActions.changePassword.reset());
		}
	}, [dispatch, changedPassword]);

	return (
		<div className={styles.container}>
			<Headernav />
			<div className={styles.card}>
				{profile.data ? (
					<ul>
						<Row title="Username">{profile.data.id}</Row>
						<Row title="Name">{profile.data.name}</Row>
						<Row title="Email">{profile.data.email}</Row>
						{formShown ? (
							<form onSubmit={onSubmit}>
								<Fieldset title="Password">
									<FormGroup>
										<InputField
											type="password"
											required={true}
											placeholder="Old Password"
											name="oldPassword"
											autoComplete="current-password"
											autoFocus={true}
											disabled={
												changedPassword.status === AsyncState.inProgress
											}
										/>
									</FormGroup>
									<FormGroup>
										<InputField
											type="password"
											required={true}
											placeholder="New Password"
											autoComplete="new-password"
											name="password"
											disabled={
												changedPassword.status === AsyncState.inProgress
											}
										/>
									</FormGroup>
									<FormGroup>
										<InputField
											type="password"
											required={true}
											placeholder="Re-enter Password"
											autoComplete="new-password"
											name="rePassword"
											disabled={
												changedPassword.status === AsyncState.inProgress
											}
										/>
									</FormGroup>
									{error || changedPassword.error ? (
										<div className={styles.error}>
											{error || (changedPassword.error as string)}
										</div>
									) : (
										<></>
									)}
									<div className="flex-end">
										<Button
											disabled={
												changedPassword.status === AsyncState.inProgress
											}
											type="reset"
											onClick={() => setFormShown(false)}
										>
											Cancel
										</Button>
										<Button
											type="submit"
											disabled={
												changedPassword.status === AsyncState.inProgress
											}
										>
											Update Password
										</Button>
									</div>
								</Fieldset>
							</form>
						) : (
							<Row title="Password">
								******** (
								<NavLink to="#" onClick={() => setFormShown(true)}>
									Change
								</NavLink>
								)
							</Row>
						)}
						{profile.data.parent ? (
							<Row title="Parent">
								<NavLink to={RoutesEnum.ASSOCIATES + "/" + profile.data.parent}>
									{profile.data.parent}
								</NavLink>
							</Row>
						) : (
							<></>
						)}
						<Row title="Sponsers">
							{Number(profile.data.members?.length || 0)}
						</Row>
						<Row title="Children">
							{profile.data.children.map((node) => (
								<NavLink to={RoutesEnum.ASSOCIATES + "/" + node}>
									{node}
								</NavLink>
							))}
						</Row>
					</ul>
				) : (
					"Member Not Found"
				)}
			</div>
		</div>
	);

	function onSubmit(e: React.FormEvent) {
		e.preventDefault();
		setError("");

		//@ts-ignore
		const oldPassword = e.target?.["oldPassword"]?.value;
		//@ts-ignore
		const password = e.target?.["password"]?.value;
		//@ts-ignore
		const rePassword = e.target?.["rePassword"]?.value;

		if (password !== rePassword) {
			setError("Passwords do not match");
			return;
		}

		dispatch(
			associateActions.changePassword.start({
				oldPassword,
				password,
			})
		);
	}
};
