import axios from "axios";
import { ASSOCIATES_URL } from "../constants/url.constant";
import { AssociateType } from "../types/associate.type";

const createAssociate = async (
	token: string,
	associate: any
): Promise<{ data: AssociateType }> => {
	const { data } = await axios.post(ASSOCIATES_URL, associate, {
		headers: {
			Authorization: "Bearer " + token,
		},
	});

	return data;
};

const changePassword = async (
	token: string,
	oldPassword: string,
	password: string
): Promise<{ data: boolean }> => {
	const { data } = await axios.post(
		ASSOCIATES_URL + "/reset",
		{ oldPassword, password },
		{
			headers: {
				Authorization: "Bearer " + token,
			},
		}
	);

	return data;
};

const resetPassword = async (
	token: string,
	id: string,
	password: string
): Promise<{ data: boolean }> => {
	const { data } = await axios.put(
		ASSOCIATES_URL + "/reset/" + id,
		{ password },
		{
			headers: {
				Authorization: "Bearer " + token,
			},
		}
	);

	return data;
};

const listAssociates = async (
	token: string,
	page: number
): Promise<{ data: AssociateType[] }> => {
	const { data } = await axios.get(ASSOCIATES_URL + `?offset=${page * 10}`, {
		headers: {
			Authorization: "Bearer " + token,
		},
	});

	return data;
};

const getAssociateDetails = async (
	token: string,
	id: string
): Promise<{ data: AssociateType }> => {
	const { data } = await axios.get(ASSOCIATES_URL + "/" + id, {
		headers: {
			Authorization: "Bearer " + token,
		},
	});

	return data;
};

const getProfile = async (token: string): Promise<{ data: AssociateType }> => {
	const { data } = await axios.get(ASSOCIATES_URL + "/profile", {
		headers: {
			Authorization: "Bearer " + token,
		},
	});

	return data;
};

export const associateServices = {
	listAssociates,
	createAssociate,
	getAssociateDetails,
	changePassword,
	resetPassword,
	getProfile,
};
