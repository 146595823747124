import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { RootState } from "../store";

const _selectPage = (state: RootState) => state.associate.page;
const _selectHasMore = (state: RootState) => state.associate.hasMore;
const _selectAssociates = (state: RootState) => state.associate.associates;
const _selectProfile = (state: RootState) => state.associate.profile;
const _selectChangedPassword = (state: RootState) =>
	state.associate.changePassword;
const _selectResetPassword = (state: RootState) =>
	state.associate.resetPassword;
const _selectCurrentAssociate = (state: RootState) =>
	state.associate.currentAssociate;
const _selectCreatedAssociate = (state: RootState) =>
	state.associate.createdAssociate;

export const selectAssociatesPage = createDraftSafeSelector(
	_selectPage,
	(state) => state
);

export const selectHasMoreAssociates = createDraftSafeSelector(
	_selectHasMore,
	(state) => state
);


export const selectAssociates = createDraftSafeSelector(
	_selectAssociates,
	(state) => state
);

export const selectProfile = createDraftSafeSelector(
	_selectProfile,
	(state) => state
);

export const selectChangedPassword = createDraftSafeSelector(
	_selectChangedPassword,
	(state) => state
);

export const selectResetPassword = createDraftSafeSelector(
	_selectResetPassword,
	(state) => state
);

export const selectCurrentAssociate = createDraftSafeSelector(
	_selectCurrentAssociate,
	(state) => state
);
export const selectCreatedAssociate = createDraftSafeSelector(
	_selectCreatedAssociate,
	(state) => state
);
