export const BASE_URL = process.env.REACT_APP_API_URI;
export const ADMIN_URL = BASE_URL + "/admin";
export const ASSOCIATES_URL = BASE_URL + "/associate";

export const MEMBERS_URL = ADMIN_URL + "/members";
export const PACKAGES_URL = ADMIN_URL + "/packages";
export const PAYMENTS_URL = ADMIN_URL + "/payments";
export const PRODUCTS_URL = ADMIN_URL + "/products";
export const AUTH_URL = ADMIN_URL + "/auth";
export const ASSOCIATE_AUTH_URL = ASSOCIATES_URL + "/auth";
export const ASSOCIATE_MEMBERS_URL = ASSOCIATES_URL + "/members";
