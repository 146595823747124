import { listenerMiddleware } from "./root.listener";
import { productServices } from "../../services/products.service";
import { RootState } from "../store";
import { handleErrors } from "../../utils/redux.util";
import { productActions } from "../actions/product.action";
import { productSliceActions } from "../slices/products.slice";

listenerMiddleware.startListening({
	actionCreator: productActions.listProducts.start,
	effect: async (action, listenerAPI) => {
		try {
			listenerAPI.cancelActiveListeners();

			const token =
				(listenerAPI.getState() as RootState).auth.user.data?.token || "";

			const products = (listenerAPI.getState() as RootState).product.products;
			const page = (listenerAPI.getState() as RootState).product.page;

			const result = await listenerAPI.pause(
				productServices.listProducts(token, page)
			);
			if (!result.data.length) {
				listenerAPI.dispatch(productSliceActions.setHasMore(false))
			} else {
				listenerAPI.dispatch(productSliceActions.nextPage())
			}

			listenerAPI.dispatch(productActions.listProducts.success([...products.data || [], ...result.data]));
		} catch (error: unknown) {
			handleErrors(
				error,
				listenerAPI.dispatch,
				productActions.listProducts.error
			);
		}
	},
});

listenerMiddleware.startListening({
	actionCreator: productActions.getProductDetails.start,
	effect: async (action, listenerAPI) => {
		try {
			listenerAPI.cancelActiveListeners();

			const token =
				(listenerAPI.getState() as RootState).auth.user.data?.token || "";

			const result = await listenerAPI.pause(
				productServices.getProductDetails(token, action.payload.id)
			);

			listenerAPI.dispatch(
				productActions.getProductDetails.success(result.data)
			);
		} catch (error: unknown) {
			handleErrors(
				error,
				listenerAPI.dispatch,
				productActions.getProductDetails.error
			);
		}
	},
});

listenerMiddleware.startListening({
	actionCreator: productActions.createProduct.start,
	effect: async (action, listenerAPI) => {
		try {
			listenerAPI.cancelActiveListeners();

			const token =
				(listenerAPI.getState() as RootState).auth.user.data?.token || "";

			const result = await listenerAPI.pause(
				productServices.createProduct(token, action.payload.product)
			);

			listenerAPI.dispatch(productActions.createProduct.success(result.data));
		} catch (error: unknown) {
			handleErrors(
				error,
				listenerAPI.dispatch,
				productActions.createProduct.error
			);
		}
	},
});
