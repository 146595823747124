import React from "react";
import styles from "./styles.module.scss";
import { Headernav } from "../../components/HeaderNav/Headernav";
import { NavLink, useNavigate } from "react-router-dom";

export const NotFoundScreen = () => {
	const navigate = useNavigate();

	return (
		<div className={styles.container}>
			<Headernav />
			<div className={styles.card}>
				<header className={styles.header}>
					<h3>404 Page Not Found</h3>
				</header>
				<NavLink to={"#"} onClick={() => navigate(-1)}>
					Go Back
				</NavLink>
			</div>
		</div>
	);
};
