import React, { PropsWithChildren } from "react";
import styles from "./styles.module.scss";

export type FieldsetType = PropsWithChildren & {
	title: string;
};

export const Fieldset = (props: FieldsetType) => {
	const { children, title } = props;
	return (
		<fieldset className={styles.fieldset}>
			<legend className={styles.title}>{title}</legend>
			{children}
		</fieldset>
	);
};
