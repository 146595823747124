import { ProductType } from "../../types/product.type";
import { asyncActionCreator } from "../../utils/redux.util";
import { packageSliceActions as productSliceActions } from "../slices/packages.slice";

export const productActionTypes = {
	LIST_PRODUCTS: "PRODUCTS/LIST",
	ADD_PRODUCT: "PRODUCT/ADD",
	PRODUCT_DETAILS: "PRODUCT/DETAILS",
};

export const productActions = {
	...productSliceActions,
	listProducts: asyncActionCreator<{}, ProductType[], unknown>(
		productActionTypes.LIST_PRODUCTS
	),
	createProduct: asyncActionCreator<
		{
			product: {
				id: string;
				name: string;
				mrp: number;
				cost: number;
				price: number;
				images: string[];
				description: string;
			};
		},
		ProductType,
		unknown
	>(productActionTypes.ADD_PRODUCT),
	getProductDetails: asyncActionCreator<{ id: string }, ProductType, unknown>(
		productActionTypes.PRODUCT_DETAILS
	),
};
