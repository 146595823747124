import React, { useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import styles from "./styles.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { RoutesEnum } from "../../constants/routes";
import { Icon } from "../../components/Icon/Icon";
import { Headernav } from "../../components/HeaderNav/Headernav";
import { selectHasMoreRecruits, selectRecruits } from "../../redux/selectors/members.selector";
import { memberActions } from "../../redux/actions/members.action";
import { memberSliceActions } from "../../redux/slices/members.slice";
import InfiniteScroll from "react-infinite-scroller";

export const MembersScreen = () => {
	const { id } = useParams()
	const dispatch = useAppDispatch();
	const members = useAppSelector(selectRecruits);
	const hasMore = useAppSelector(selectHasMoreRecruits)

	useEffect(() => {
		dispatch(memberActions.listRecruits.reset());
		dispatch(memberSliceActions.resetPage())
		dispatch(memberSliceActions.setHasMore(true))
		dispatch(memberActions.listRecruits.start({ id }));
	}, [dispatch, id]);

	const loadMore = () => {
		dispatch(memberActions.listRecruits.start({ id }));
	}

	return (
		<section className={styles.container}>
			<Headernav />
			<InfiniteScroll
				className={styles.main}
				pageStart={0}
				loadMore={loadMore}
				hasMore={hasMore}
				loader={<div className="loader" key={0}>Loading ...</div>}
			>
				{members.data?.length
					? members.data.map((member) => (
						<div
							className={styles.row}
							key={member.id}
						>
							<header className={styles.header}>
								<div>{member.id}</div>
								<div>{member.createdAt?.substring(0, 10)}</div>
							</header>
							<div className={styles.content}>
								<div>{member.firstName + " " + (member.lastName || "")}</div>
								<div>{member.phone}</div>
							</div>
							<footer className={styles.footer}>
								<div>&#8377;{member.revenue}</div>
							</footer>
						</div>
					))
					: "Nothing to Show"}
			</InfiniteScroll>
			<div className={styles.addMember}>
				<NavLink to={RoutesEnum.MEMBERS + "/add"}>
					<Icon name="add" fontSize="2rem" />
				</NavLink>
			</div>
		</section>
	);
};
