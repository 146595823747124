//TODO: Need a Default Route
export const RoutesEnum = {
	LOGIN: "/login",
	VIEW_TREE: "/",
	MEMBERS: "/members",
	ASSOCIATES: "/associates",
	PROFILE: "/profile",
	PRODUCTS: "/products",
	PACKAGES: "/packages",
};
