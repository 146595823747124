import axios from "axios";
import { ASSOCIATE_MEMBERS_URL, MEMBERS_URL } from "../constants/url.constant";
import { MemberType } from "../types/member.type";

const createMember = async (
	token: string,
	member: any
): Promise<{ data: MemberType }> => {
	const { data } = await axios.post(MEMBERS_URL, member, {
		headers: {
			Authorization: "Bearer " + token,
		},
	});

	return data;
};

const listMembers = async (
	token: string,
	username?: string
): Promise<{ data: MemberType[] }> => {
	const { data } = await axios.get(MEMBERS_URL, {
		params: {
			id: username,
		},
		headers: {
			Authorization: "Bearer " + token,
		},
	});

	return data;
};

const listRecruits = async (
	token: string,
	page: number,
	id?: string
): Promise<{ data: MemberType[] }> => {
	const { data } = await axios.get(ASSOCIATE_MEMBERS_URL + (id ? `/${id}` : "") + `?offset=${page * 10}`, {
		params: { id },
		headers: {
			Authorization: "Bearer " + token,
		},
	});

	return data;
};

const getMemberDetails = async (
	token: string,
	userName: string
): Promise<{ data: MemberType }> => {
	const { data } = await axios.get(MEMBERS_URL + "/" + userName, {
		headers: {
			Authorization: "Bearer " + token,
		},
	});

	return data;
};

const resetPassword = async (
	token: string,
	id: string,
	password: string
): Promise<{ data: boolean }> => {
	const { data } = await axios.put(
		MEMBERS_URL + "/reset/" + id,
		{ password },
		{
			headers: {
				Authorization: "Bearer " + token,
			},
		}
	);

	return data;
};


export const memberServices = {
	listMembers,
	listRecruits,
	createMember,
	getMemberDetails,
	resetPassword
};
