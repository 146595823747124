import axios from "axios";
import { PAYMENTS_URL } from "../constants/url.constant";
import { MemberType } from "../types/member.type";

const initiate = async (
	token: string,
	userName: string,
	packageId: string
): Promise<{ data: MemberType }> => {
	const { data } = await axios.post(
		PAYMENTS_URL + "/package",
		{ id: userName, package: packageId },
		{
			headers: {
				Authorization: "Bearer " + token,
			},
		}
	);

	return data;
};

export const paymentServices = {
	initiate,
};
