import React from "react";
import { useAppSelector } from "../../hooks/redux.hook";
import { selectCurrentUser } from "../../redux/selectors/auth.selector";
import { Navigate, Outlet } from "react-router-dom";
import { RoutesEnum } from "../../constants/routes";

export type GuardRouteProps = {
	guarded?: boolean;
	roles?: string[];
};

export const GuardRoute = (props: GuardRouteProps) => {
	const { guarded, roles } = props;
	const user = useAppSelector(selectCurrentUser);

	if (guarded && !roles?.includes(user.data?.role || "")) {
		//TODO: Redirect to Previous URL
		return <Navigate to={RoutesEnum.LOGIN} replace={true} />;
	} else if (!guarded && user.data?.role) {
		if (user.data?.role === "admin") {
			return <Navigate to={RoutesEnum.VIEW_TREE} replace={true} />;
		} else {
			return <Navigate to={RoutesEnum.PROFILE} replace={true} />;
		}
	} else return <Outlet />;
};
