import { AuthType } from "../../types/admin.type";
import { asyncActionCreator } from "../../utils/redux.util";
import { authSliceActions } from "../slices/auth.slice";

export const authActionTypes = {
	LOGIN: "AUTH/LOGIN",
};

export const authActions = {
	...authSliceActions,
	login: asyncActionCreator<
		{ email: string; password: string; role: string },
		AuthType,
		unknown
	>(authActionTypes.LOGIN),
};
