import React from "react";
import styles from "./styles.module.scss";

export type IconButtonProps = {
	onClick?: React.MouseEventHandler;
	name: string;
	filled?: boolean;
	className?: string;
	disabled?: boolean;
	color?: string;
	fontSize?: string;
	iconClassName?: string;
};

export const IconButton = (props: IconButtonProps) => {
	const { onClick, name, color, disabled, filled, fontSize, iconClassName } =
		props;
	return (
		<div className={styles.container} onClick={disabled ? () => {} : onClick}>
			<span
				className={`material-symbols-${filled ? "rounded" : "outlined"} ${
					iconClassName ? iconClassName : ""
				} ${disabled ? styles.disabled : ""}`}
				style={{ color, fontSize }}
			>
				{name}
			</span>
		</div>
	);
};
