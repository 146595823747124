import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncProperty } from "../../types/redux.type";
import { reduxConstants } from "../../constants/redux.constant";
import { addAsyncCase } from "../../utils/redux.util";
import { ProductType } from "../../types/product.type";
import { productActions } from "../actions/product.action";

type ProductSliceType = {
	products: AsyncProperty<ProductType[]>;
	createdProduct: AsyncProperty<ProductType>;
	currentProduct: AsyncProperty<ProductType>;
	page: number;
	hasMore: boolean;
};

const initialState: ProductSliceType = {
	products: reduxConstants.defaultAsyncState,
	createdProduct: reduxConstants.defaultAsyncState,
	currentProduct: reduxConstants.defaultAsyncState,
	page: 0,
	hasMore: false
};

export const productLabel = "product";

export const productSlice = createSlice({
	name: productLabel,
	initialState,
	reducers: {
		nextPage(state) {
			state.page += 1;
		},
		resetPage(state) {
			state.page = 0
		},
		setHasMore(state, action: PayloadAction<boolean>) {
			state.hasMore = action.payload
		}
	},
	extraReducers: (builder) => {
		addAsyncCase(builder, productActions.listProducts, "products");
		addAsyncCase(builder, productActions.getProductDetails, "currentProduct");
		addAsyncCase(builder, productActions.createProduct, "createdProduct");
	},
});

export const productSliceActions = productSlice.actions;
