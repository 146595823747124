import { PackageType } from "../../types/package.type";
import { asyncActionCreator } from "../../utils/redux.util";
import { packageSliceActions } from "../slices/packages.slice";

export const packageActionTypes = {
	LIST_PACKAGES: "PACKAGE/LIST",
	ADD_PACKAGE: "PACKAGE/ADD",
};

export const packageActions = {
	...packageSliceActions,
	listPackages: asyncActionCreator<{}, PackageType[], unknown>(
		packageActionTypes.LIST_PACKAGES
	),
	createPackage: asyncActionCreator<
		{ package: PackageType },
		PackageType,
		unknown
	>(packageActionTypes.ADD_PACKAGE),
};
