import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import styles from "./styles.module.scss";
import { useAppDispatch, useAppSelector } from "../../hooks/redux.hook";
import { RoutesEnum } from "../../constants/routes";
import { Icon } from "../../components/Icon/Icon";
import { Headernav } from "../../components/HeaderNav/Headernav";
import { associateActions } from "../../redux/actions/associates.action";
import { selectAssociates, selectHasMoreAssociates } from "../../redux/selectors/associates.selector";
import { associateSliceActions } from "../../redux/slices/associates.slice";
import InfiniteScroll from "react-infinite-scroller";

export const AssociatesScreen = () => {
	const dispatch = useAppDispatch();
	const associates = useAppSelector(selectAssociates);
	const hasMore = useAppSelector(selectHasMoreAssociates)

	useEffect(() => {
		dispatch(associateActions.listAssociates.reset());
		dispatch(associateSliceActions.resetPage())
		dispatch(associateSliceActions.setHasMore(true))
		dispatch(associateActions.listAssociates.start({}));
	}, [dispatch]);

	const loadMore = () => {
		dispatch(associateActions.listAssociates.start({}));
	}

	return (
		<section className={styles.container}>
			<Headernav />

			<InfiniteScroll
				className={styles.main}
				pageStart={0}
				loadMore={loadMore}
				hasMore={hasMore}
				loader={<div className="loader" key={0}>Loading ...</div>}
			>
				{associates.data?.length
					? associates.data.map((associate) => (
						<NavLink
							key={associate.id}
							to={RoutesEnum.ASSOCIATES + "/" + associate.id}
							className={styles.row}
						>
							<div>{associate.name}</div>
							<div>{associate.id}</div>
							<div className={styles.level}>{associate.level}</div>
						</NavLink>
					))
					: "Nothing to Show"}
			</InfiniteScroll>
			<div className={styles.addMember}>
				<NavLink to={RoutesEnum.ASSOCIATES + "/add"}>
					<Icon name="add" fontSize="2rem" />
				</NavLink>
			</div>
		</section>
	);
};
