import { createAction } from "@reduxjs/toolkit";

export enum AsyncState {
	idle = "IDLE",
	inProgress = "IN_PROGRESS",
	success = "SUCCESS",
	failed = "FAILED",
}

export type AsyncProperty<T> = {
	status: AsyncState;
	data?: T;
	error?: unknown;
};

export type AsyncAction = {
	start: ReturnType<typeof createAction>;
	success: ReturnType<typeof createAction>;
	error: ReturnType<typeof createAction>;
	reset: ReturnType<typeof createAction>;
};

export type AsyncErrorType = {
	code?: number;
	message: string;
};
