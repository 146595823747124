import { listenerMiddleware } from "./root.listener";
import { packageServices } from "../../services/packages.service";
import { packageActions } from "../actions/package.action";
import { RootState } from "../store";
import { handleErrors } from "../../utils/redux.util";

listenerMiddleware.startListening({
	actionCreator: packageActions.listPackages.start,
	effect: async (action, listenerAPI) => {
		try {
			listenerAPI.cancelActiveListeners();

			const token =
				(listenerAPI.getState() as RootState).auth.user.data?.token || "";

			const result = await listenerAPI.pause(
				packageServices.listPackages(token)
			);

			listenerAPI.dispatch(packageActions.listPackages.success(result.data));
		} catch (error: unknown) {
			handleErrors(
				error,
				listenerAPI.dispatch,
				packageActions.listPackages.error
			);
		}
	},
});

listenerMiddleware.startListening({
	actionCreator: packageActions.createPackage.start,
	effect: async (action, listenerAPI) => {
		try {
			listenerAPI.cancelActiveListeners();

			const token =
				(listenerAPI.getState() as RootState).auth.user.data?.token || "";

			const result = await listenerAPI.pause(
				packageServices.createPackage(token, action.payload.package)
			);

			listenerAPI.dispatch(packageActions.createPackage.success(result.data));
		} catch (error: unknown) {
			handleErrors(
				error,
				listenerAPI.dispatch,
				packageActions.createPackage.error
			);
		}
	},
});
